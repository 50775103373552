@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
*
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
}


body
{
    font-family: 'Roboto', sans-serif;
}
a
{
    text-decoration: none;
}
.product-card {
    width: 380px;
    position: relative;
    box-shadow: 0 2px 7px #dfdfdf;
    margin: 50px auto;
    background: #fafafa;
}

.badge {
    position: absolute;
    left: 0;
    top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    background: red;
    color: #fff;
    padding: 3px 10px;
}

.product-tumb {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 50px;
    background: #f0f0f0;
    background-image: url("../../assets/pexels-damir-mijailovic-3587015.jpeg");
    background-size: cover;
}

.product-tumb2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 50px;
    background: #f0f0f0;
    background-image: url("../../assets/fallprotect22.jpeg");
    background-size: cover;
}

.product-tumb3 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 50px;
    background: #f0f0f0;
    background-image: url("../../assets/workirebet.jpg");
    background-size: cover;
}

.product-tumb img {
    max-width: 100%;
    max-height: 100%;
    display: none;
}

.product-details {
    padding: 30px;
}

.product-catagory {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: 18px;
}

.product-details h4 a {
    font-weight: 500;
    display: block;
    margin-bottom: 18px;
    text-transform: uppercase;
    color: #363636;
    text-decoration: none;
    transition: 0.3s;
}

.product-details h4 a:hover {
    color: #fbb72c;
}

.product-details p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 18px;
    color: #999;
}

.product-bottom-details {
    overflow: hidden;
    border-top: 1px solid #eee;
    padding-top: 20px;
}

.product-bottom-details div {
    float: left;
    width: 50%;
}

.product-price {
    font-size: 18px;
    color: #fbb72c;
    font-weight: 600;
}

.product-price small {
    font-size: 80%;
    font-weight: 400;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 5px;
}

.product-links {
    text-align: right;
}

.product-links a {
    display: inline-block;
    margin-left: 5px;
    color: #e1e1e1;
    transition: 0.3s;
    font-size: 17px;
}

.product-links a:hover {
    color: #fbb72c;
}

.content, .testimonials{
    overflow: auto;
    position: relative;
}
.content::before, .testimonials::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  
    background-image: url('../../assets/background.jpeg');
    background-attachment: fixed;
    background-size: auto;
    width: 100%;
    height: 100%;
  
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(20%);
}

.testimonials::before {
  background-image: url('../../assets/background.jpeg');

}

.center_using_calc {
  margin-left: calc(50% - 50px);
}

.dotted-ul {
    list-style: none; /* Remove default bullets */
    list-style-position: inside;
  }
  
  .dotted-ul li::before {
    content: "\25A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #C28563; /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }