@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
.snip1566 {
  position: relative;
  display: inline-block;
  max-width: 300px;
  width: 100%;
  color: #2A9EDA;
  font-size: 16px;
  box-shadow: none !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.snip1566 *,
.snip1566:before,
.snip1566:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.snip1566:before,
.snip1566:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  border: 2px solid #2A9EDA;
  border-color: transparent #2A9EDA;
}

.snip1566 img {
  width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
  border-radius: 50%;
  padding: 10px;
}

.snip1566 figcaption {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 50%;
}

.snip1566 i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 4em;
  z-index: 1;
}

.snip1566 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.snip1566:hover figcaption,
.snip1566.hover figcaption {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.snip1566:hover:before,
.snip1566.hover:before,
.snip1566:hover:after,
.snip1566.hover:after {
  border-width: 10px;
}

.snip1566:hover:before,
.snip1566.hover:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.snip1566:hover:after,
.snip1566.hover:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
